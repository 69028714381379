import { Grid, Typography, } from "@mui/material";
import { Box } from "@mui/system";

import socolLogo from './portfolio/socol.svg'
import cliqueLogo from './portfolio/clique.png'
import logo from './logo.svg'
import './App.css'


function App() {
  return (
    <>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={12}>
            <div style={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap',
            }}>
              <img alt='logo' src={logo} width={50} height={50} />
              &nbsp;&nbsp;
              <Typography variant="h5">Alfalfa Capital</Typography>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 2 }} />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography variant="h2">
              We invest in Web3 companies,<br />
              with a focus on <strong>finance</strong>, and <strong>culture</strong>.
            </Typography>
          </Grid>
          <Grid item md={4}>
            <Typography variant="h5">
              For all enquires please reach out to <br />
              hello [at] alfalfa.capital.<br /><br />
              Don't be shy, we don't bite.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ p: 2 }} />

      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item md={8}>
            <Typography variant="h4">
              Portfolio
            </Typography>
            <br />
            <a href="https://socol.io"><img alt='socol' src={socolLogo} height={64} /></a>
            &nbsp;
            &nbsp;
            &nbsp;
            &nbsp;
            <a href="https://www.clique.social/"><img alt='clique' src={cliqueLogo} height={64} /></a>
          </Grid>
          <Grid item md={4}>
            <Typography variant="h4">
              Team
            </Typography>
            <Typography variant="a">
              <a href="https://twitter.com/libevm">@libevm</a><br />
              <a href="https://twitter.com/0xminimoon">@0xminimoon</a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default App;
